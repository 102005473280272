import React, { useState, useEffect } from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import renderBloks from '@renderBloks'
import { Container, AppBar, Tabs } from '@material-ui/core'
import classNames from 'classnames'
import isInBrowser from '@helpers/is-in-browser'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'

const useStyles = makeStyles((theme) => ({
  appBarContainer: {
    minHeight: 48,
  },
  root: {
    backgroundColor: (props) => props.blok.backgroundColor.color,
    color: ({ blok: { foregroundColor }, isTopNavBanner }) =>
      (!!foregroundColor && foregroundColor.color) ||
      (isTopNavBanner && theme.palette.common.white) ||
      theme.palette.primary.main,
    padding: (props) => props.isTopNavBanner && '10px 0px',
    position: 'sticky',
    top: 60,
    '& div.MuiTabs-scroller': {
      whiteSpace: 'normal',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignSelf: 'center',
    minHeight: (props) => props.blok.MinHeighSticky, // TODO: update this misspelling here, 2 lines down, and in storyBlok
    '& a > button': {
      minHeight: (props) => props.blok.MinHeighSticky,
    },
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  stuckContainer: {
    width: '100vw',
    position: 'fixed',
    left: 0,
  },
  tabContainer: {
    alignItems: 'center',
  },
}))

const StickyContainer = (props) => {
  const [isScroll, setIsScroll] = useState(false)
  const classes = useStyles(props)

  useEffect(() => {
    if (isInBrowser) {
      window.addEventListener('scroll', updateStickyStyle, { passive: true })
    }
  })

  const updateStickyStyle = () => {
    if (window.pageYOffset >= 500) {
      setIsScroll((isScroll) => true)
    } else {
      setIsScroll((isScroll) => false)
    }
  }

  return (
    <SbEditable content={props.blok}>
      <PageContainer>
        <div className={classes.appBarContainer}>
          <AppBar
            className={classNames(classes.root, {
              [classes.stuckContainer]: isScroll,
            })}
          >
            <Container fixed className={classes.container}>
              <Tabs
                value={false}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="side-scrolling tabs"
                className={classes.tabContainer}
              >
                {renderBloks(props.blok.items)}
              </Tabs>
            </Container>
          </AppBar>
        </div>
      </PageContainer>
    </SbEditable>
  )
}

export default StickyContainer
